import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// React icons
import {  FaFacebook } from 'react-icons/fa';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";

import team1 from "assets/img/faces/dana.jpg";
import team3 from "assets/img/faces/christian.jpg";

class TeamSection extends React.Component {
  render() {
    const { classes } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    return (
      <div className={classes.section}>
        <h2 className={classes.title}>Wir sind für Sie da</h2>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img src={team3} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Christian Schreiner
                  <br />
                  <small className={classes.smallTitle}>Buersche Falken-Apotheke</small>
                </h4>
                <p className={classes.smallDiscription}>
                  Apotheker<br />
                  Geriatrische Pharmazie<br />
                  Zertifikatinhaber „Palliativpharmazie: <br />der Apotheker als Teil des Palliative Care Teams„<br />
                  Kreisvertrauensapotheker und<br />
                  Beauftragter für Öffentlichkeitsarbeit<br />
                  für Gelsenkirchen<br />
                </p>
                <CardBody>
                  <h2 className={classes.title}>
                    &bdquo;Wir haben immer ein offenes Ohr für Sie!&ldquo;
                  </h2>
                </CardBody>
                <CardFooter className={classes.justifyCenter}>

                  <Button
                      justIcon
                      color="transparent"
                      href="https://www.facebook.com/BuerscheFalkenApotheke/"
                      className={classes.margin5}
                  >
                    <FaFacebook/>
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img src={team1} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Dana Schreiner
                  <br />
                  <small className={classes.smallTitle}>
                    Dom-Apotheke
                  </small>

                </h4>
                <p className={classes.smallDiscription}>
                  Apothekerin<br />
                  Fachapothekerin für Allgemeinpharmazie<br />
                  Naturheilverfahren und Homöopathie<br />
                  Geriatrische Pharmazie<br />
                  AMTS-Managerin<br />
                  <br />
                  <br />
                </p>
                <CardBody>
                  <h2 className={classes.title}>
                    &bdquo;Ihre Gesundheit liegt mir am Herzen!&ldquo;
                  </h2>
                </CardBody>
                <CardFooter className={classes.justifyCenter}>

                  <Button
                      justIcon
                      href="https://www.facebook.com/domapothekebuer"
                      color="transparent"
                      className={classes.margin5}
                  >
                    <FaFacebook/>
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>


          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(teamStyle)(TeamSection);
